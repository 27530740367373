import { FeatureData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../components/View';
import Form from '../../../../../containers/Espace/Form';
import params from '../../../../../params/connaissance/collection/index.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const PageEspaceCollectionUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, collectionId },
}) => {
  const model = new FeatureData({
    espaceId,
    params,
    collectionName: 'collections',
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={collectionId}
          itemPathnamePrefix={`/espaces/${espaceId}/collections/`}
          model={model}
          name="collection"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceCollectionUpdate);
